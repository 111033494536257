// Stimulus JS
import '@global/turbo-power-initializer';
import { application } from '@/src/global/stimulus-initializer';
import StimulusControllerResolver, {
  createViteGlobResolver
} from '@global/stimulus-resolver';
import MobileNavController from '@/src/jobseeker/main/js/controllers/mobile-nav-controller';
import CookiehubController from '@/src/shared/js/controllers/cookiehub-controller';
import SliderController from './controllers/slider-controller';
import YTooltipController from '@yobbers/js/controllers/y-tooltip-controller';
import YChoiceTagsController from '@yobbers/js/controllers/y-choice-tags-controller';
import YLazyloadController from '@yobbers/js/controllers/y-lazyload-controller';
import ModalController from '@yobbers/js/controllers/modal-controller';
import YFormController from '@yobbers/js/controllers/y-form-controller';
import ScrollActionController from '@jobseeker/account/js/controllers/scroll-action-controller';
import DropdownController from '@yobbers/js/controllers/dropdown-controller';
import ItemSelectorController from '@yobbers/js/controllers/item-selector-controller';
import YTabsController from '@yobbers/js/controllers/y-tabs-controller';
import { initRipplet } from '@global/addons/ripple-effect';
import '@/src/global/turbo-confirm-alert';
import OverlayController from './controllers/overlay-controller';
import DynamicSearchPlaceholder from '@/src/jobseeker/main/js/controllers/dynamic-search-placeholder-controller';
import { importComponentControllers } from '@/src/global/component-controller-importer';
import '~/images/svg/trustpilot/logo_dark.svg';

application.register('cookiehub', CookiehubController);

// Cherrypicked controllers
application.register('modal', ModalController);
application.register('mobile-nav', MobileNavController);
application.register('slider', SliderController);
application.register('y-lazyload', YLazyloadController);
application.register('scroll-action', ScrollActionController);
application.register('y-form', YFormController);
application.register('dropdown', DropdownController);
application.register('tooltip', YTooltipController);
application.register('y-choice-tags', YChoiceTagsController);
application.register('y-tabs', YTabsController);
application.register('overlay', OverlayController);
application.register('item-selector', ItemSelectorController);
application.register('dynamic-search-placeholder', DynamicSearchPlaceholder);

// Load controller when connected
StimulusControllerResolver.install(
  application,
  createViteGlobResolver(
    import.meta.glob('./controllers/*-controller.{js,ts,jsx}'),
    import.meta.glob('@global/controllers/*-controller.{js,ts}'),
    import.meta.glob('@shared/js/controllers/*-controller.{js,ts}'),
    import.meta.glob('@/src/tracking/controllers/*-controller.{js,ts}')
  )
);

importComponentControllers(application);

document.addEventListener('turbo:load', () => initRipplet());

export { application };
