const importComponentControllers = (application) => {
  const controllers = import.meta.glob('@components/**/controller.js', {
    eager: true
  });

  Object.entries(controllers).forEach(([path, controller]) => {
    const name = path
      .match(/components\/(.+)\/controller\.(js|ts)$/)[1]
      .replaceAll('/', '--')
      .replaceAll('_', '-');
    application.register(name, controller.default);
  });
};

export { importComponentControllers };
